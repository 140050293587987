.diagram-container {
  position: relative;
  width: 100%;
  /* height: 70vh; */
  height: fit-content;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.controls {
  position: relative;
  top: 10px;
  left: 10px;
  z-index: 10;
  display: flex;
  flex-direction: column;
  height: 3rem;
}

.diagram-wrapper {
  position: relative;
  width: 100%;
  height: fit-content;
  overflow: auto;
}

.diagram-content {
  position: relative;
  display: inline-block;
  transform-origin: 0 0; /* Ensure scaling starts from the top-left corner */
}

.diagram-image {
  max-width: 100%;
  max-height: 100%;
}

.indicator {
  position: absolute;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  border: 2px solid white;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
  transition: top 0.2s, left 0.2s;
}

/* Styles for different indicator types */
.style1 {
  background-color: red;
}

.style2 {
  background-color: blue;
  width: 30px;
  height: 30px;
}

.style3 {
  background-color: green;
  border-radius: 0;
  width: 20px;
  height: 20px;
}

.style4 {
  width: 8px;
  height: 8px;
  border: 1px solid green;
  border-radius: 4px;
  opacity: 0.5;
  animation: pulse 1s infinite;
}
/* 8px, 14px, 20px */

@media (min-width: 568px) {
  .style4 {
    width: 14px;
    height: 14px;
    border: 2px solid green;
  }
}

@media (min-width: 1000px) {
  .style4 {
    width: 20px;
    height: 20px;
  }
}

@media (min-width: 1200px) {
  .style4 {
    width: 25px;
    height: 25px;
  }
}

@keyframes pulse {
  0% {
    transform: scale(1);
    opacity: 0.5;
  }
  50% {
    transform: scale(1.2);
    opacity: 1;
  }
  100% {
    transform: scale(1);
    opacity: 0.5;
  }
}
