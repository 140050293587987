@import './Layout/NavBar/navbar.scss';
@import './BiReports/bireports.scss';
@import './Purchase/purchase.scss';



.h-48-px {
  height: 48px;
}

.p-0-important{
  padding: 0px !important;
}

.title-box-shadow {
  box-shadow: 0px 2px 4px -3px rgba(34, 51, 84, 0.1), 0px 5px 12px -4px rgba(34, 51, 84, 0.05);
}

.filter-container {
  height: 87px;
  padding: 22px;
  @apply title-box-shadow;
}

.order-summary-container {
  @apply title-box-shadow;
  @apply h-48-px;
}
/* Chrome, Safari, Edge, Opera */
input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}
button:disabled{
  background-color: #CCCEDD !important;
  color: #6b7280;
}

[multiple],
[type='date'],
[type='datetime-local'],
[type='email'],
[type='month'],
[type='number'],
[type='password'],
[type='search'],
[type='tel'],
[type='text'],
[type='time'],
[type='url'],
[type='week'],
select,
textarea {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  background-color: #fff;
  border-color: #6b7280;
  border-width: 1px;
  padding-top: 0.5rem;
  padding-right: 0.75rem;
  padding-bottom: 0.5rem;
  padding-left: 0.75rem;
  font-size: 1rem;
  line-height: 1.5rem;
}
select{
  height: 42px;
}

.rounded-r-md {
  border-radius: 0.375rem !important;
  border-radius: 0.375rem !important;
}

.border-gray-300 {
  --tw-border-opacity: 1;
  border-color: rgba(209, 213, 219, var(--tw-border-opacity)) !important;
}

.header-table {
  line-height: 1.5rem;
  display: table-cell;
  vertical-align: inherit;
  border-bottom: 1px solid rgba(34, 51, 84, 0.1);
  text-align: left;
  padding: 16px;
  text-transform: uppercase;
  font-size: 13px;
  font-weight: bold;
  color: rgba(34, 51, 84, 0.7);
}

select {
  background-image: url(../images/svg/iconArrowDown.svg);
  background-position: right 0.5rem center;
  background-repeat: no-repeat;
  background-size: 1em 1em;
  padding-right: 2.5rem;
  -webkit-print-color-adjust: exact;
  color-adjust: exact;
}

.bg-blur {
  filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(255, 255, 255, 0.2) !important;
}
.dark-bg-blur {
  filter: blur(2px);
  backdrop-filter: blur(2px);
  background-color: rgba(0, 0, 0, 0.2);
}

.MuiBox-root [role='tabpanel'] {
  background-color: white;
  border: 1px solid rgba(34, 51, 84, 0.1);
  border-radius: 6px;
}

.MuiTab-root.MuiButtonBase-root {
  height: 44px;
  min-height: 44px;
  border: 1px solid rgba(34, 51, 84, 0.1);
  border-bottom: 0px;
  position: relative;
  margin-right: 9px;
  font-size: 0.875rem;
  border-bottom-left-radius: 0px;
  border-bottom-right-radius: 0px;
}
.z-0,
.MuiModal-root {
  z-index: 50;
}
.ck-editor__editable_inline {
  // min-height: 400px;
  max-height: 500px;
  // max-width: 800px;
  // min-width: 800px;
}

.h-screen-403 {
  height: calc(100vh - 70px);
}
// Loader
$size: 100px;
$duration: 1.5s;
$timing: ease;

* {
  margin: 0;
  padding: 0;
}

.parent {
  position: absolute;
  z-index: 99;

  width: 100%;
  height: 100%;

  display: flex;
  justify-content: center;
  align-items: center;

  // background: hsl(0, 0%, 95%);
  // background: rgba(0, 0, 0, 0.5);
}

.heart {
  position: relative;

  width: $size;
  height: $size;

  text-align: center;
  line-height: $size;

  background: linear-gradient(to bottom, #E8423F, darken(desaturate(#E8423F, 10), 5));
  border-radius: 50%;
  cursor: pointer;

  box-shadow: 0px 7px 20px hsla(0, 0%, 0%, 0.2);
  animation: beat $duration $timing 0s infinite;

  span {
    font-size: 22pt;

    color: white;
    text-shadow: 0px 0px 10px hsla(0, 0%, 100%, 0.4);
  }

  &:before,
  &:after {
    content: '';
    position: absolute;

    width: 100%;
    height: 100%;
    top: 0;
    left: 0;

    background-color: #E8423F;
    opacity: 0.4;
    border-radius: inherit;
  }

  &:before {
    z-index: -2;
    animation: beat-before $duration $timing 100ms infinite;
  }

  &:after {
    z-index: -1;
    animation: beat-after $duration $timing 200ms infinite;
  }
}

@keyframes beat {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.1);
  }
}

@keyframes beat-before {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.15);
  }
}

@keyframes beat-after {
  0% {
    transform: scale(1);
  }

  50% {
    transform: scale(1.3);
  }
}
.z-index-99 {
  z-index: 999999999999;
}

.widthDragAndDrop {
  max-width: 100% !important; 
}

.widthDragAndDrop {
  border: dashed 2px #E8423F !important;
  height: 70px !important;
  background-color: #ff000008;
}
.swal2-container {
  z-index: 20000 !important;
}

.react-daterange-picker{
  height: 37.13px;
}
.react-calendar{
  border-radius: 5px;
    overflow: hidden;
    margin-top: 5px;
}
.react-calendar__tile--active {
  background: rgb(2, 206, 124) !important;
  color: white;
}

.react-daterange-picker__wrapper{
  border-radius: 5px;
  border: thin solid #c4c4c4 !important;
}
.react-daterange-picker__inputGroup{
  display: flex;
  justify-content: center;
  align-items: center;
  width: 0px !important;
  padding: 0px !important;
  margin: 0px !important;
  min-width: 30px !important;
  color: rgba(34, 51, 84, 0.7);
  font-size: small !important;
}
.react-daterange-picker__calendar-button{
  margin: 5px;
}
.react-calendar__tile--hasActive {
  background: #363380 !important;
  color: white;
  border-radius: 5px;
}
input:disabled, select:disabled {
  background: #fafafa;
  cursor: not-allowed ;
}

.sticky {
    position: sticky;
    top: 0;
}
.justify-center {
  justify-content: center !important;
}
.invisible{
  display: none !important;
}
.loginBg{
  // background: url('../images/background.png');
  background: url('../images/BG-LOGIN.png');
  background-size: cover;
}

.request-window .MuiDrawer-paperAnchorTop {
  // margin: 20px;
  // border-radius: 10px;
}