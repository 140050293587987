.MuiDataGrid-columnHeaderTitleContainer {
  @apply justify-center;
}

/*CANCEL PURCHASE MODAL STYLES*/
.purchaseReasonTitleSelect {
  font-size: 16;
  margin-top: 20;
  color: #373380;
  padding-top: 1em;
  margin-bottom: 0.5em;
  display: block;
  font-weight: bold;
}
